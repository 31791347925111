import { template as template_6cdd815864f04dfa9a7e56db512ab786 } from "@ember/template-compiler";
const FKLabel = template_6cdd815864f04dfa9a7e56db512ab786(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
