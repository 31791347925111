import { template as template_7c45ab41ce1b49f4a16f06a0d1cdf83e } from "@ember/template-compiler";
const SidebarSectionMessage = template_7c45ab41ce1b49f4a16f06a0d1cdf83e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
